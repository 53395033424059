import { graphql } from "gatsby";
import React from "react";
import { Footer, Header } from "../components/layout/index";
import {
  BlogListing,
  Cta,
  Faq,
  Features,
  Hero,
  KeyFigures,
  Partners,
  References,
  Roles,
  Security,
  Testimonials,
  DynamicContactForm,
  DynamicContactFormThanks,
} from "../components/sections";
import { Seo } from "../components/Seo";
import { lightBackgroundColor } from "../constants";
import { getOppositeBackgroundColor } from "../utils";

/**
 * List of sanity types and their renderable components.
 */
const typeToComponent = {
  hero: Hero,
  keyFiguresSection: KeyFigures,
  rolesSection: Roles,
  references: References,
  dynamicPageTestimonials: Testimonials,
  dynamicPageBlogListing: BlogListing,
  partners: Partners,
  security: Security,
  faqSection: Faq,
  ctaBanner: Cta,
  contactForm: DynamicContactForm,
  contactFormThanks: DynamicContactFormThanks,
  featuresSection: Features,
};

/**
 * List of sanity types where the background color switch is skipped, when they are the previous or next section to be rendered.
 */
const skipBackgroundColorSwitch = {
  previous: ["dynamicPageTestimonials", "dynamicPageBlogListing"],
  next: ["keyFiguresSection", "dynamicPageTestimonials"],
};

/**
 * Compute the next section's background color, taking into account when we need to skip the switch,
 * depending on the previous and the next section type.
 */
const getNextBackgroundColor = ({
  currentBackgroundColor,
  previousSectionType,
  nextSectionType,
}) => {
  if (
    !previousSectionType ||
    skipBackgroundColorSwitch.previous.includes(previousSectionType) ||
    skipBackgroundColorSwitch.next.includes(nextSectionType)
  ) {
    return currentBackgroundColor;
  }
  return getOppositeBackgroundColor(currentBackgroundColor);
};

/**
 * Render the given section with the given background, with adapted props for the static components
 * @todo: this function should disappear once we adapt correctly the components' props
 */
const renderSection = (section, backgroundColor) => {
  const { _key, _type, ...props } = section;

  const ToRender = typeToComponent[_type];

  if (_type === "keyFiguresSection") {
    props.keyFigures = [
      { ...props.keyFigure1, prefix: "+" },
      { ...props.keyFigure2, prefix: "+" },
      { ...props.keyFigure3, prefix: "+" },
    ];
  }
  if (_type === "references") {
    props.header = props.title;
    props.references = { logos: [...props.logos] };
  }
  if (_type === "partners") {
    props.partners = { logos: [...props.logos] };
  }

  return ToRender ? (
    <ToRender key={_key} {...props} backgroundColor={backgroundColor} />
  ) : null;
};

/**
 * Get all the listed types and render their respective component.
 * Alternate the background color of the sections except when explicitely skipped
 */
const renderAllSections = pageSections => {
  let backgroundColor = lightBackgroundColor;
  let previousSectionType = undefined;

  const renderedSections = [];

  pageSections.forEach(section => {
    backgroundColor = getNextBackgroundColor({
      currentBackgroundColor: backgroundColor,
      previousSectionType,
      nextSectionType: section._type,
    });
    const renderedSection = renderSection(section, backgroundColor);

    if (renderedSection !== null) {
      renderedSections.push(renderedSection);
      previousSectionType = section._type;
    }
  });

  return renderedSections;
};

/**
 * A Dynamic page. Given the list of sections, render the whole page correctly with the corresponding components, and with an alternating background.
 */
const DynamicPage = ({ data: { page }, pageContext: { slug } }) => {
  const {
    pageSections = [],
    title,
    seoDescription,
    seoKeywords,
    navLanguage,
  } = page;

  return (
    <>
      <Seo title={title} description={seoDescription} keywords={seoKeywords} />
      <Header language={navLanguage} />
      {renderAllSections(pageSections)}
      <Footer language={navLanguage} />
    </>
  );
};

export default DynamicPage;

export const pageQuery = graphql`
  query DynamicPagesBySlug($slug: String!) {
    page: sanityDynamicPage(slug: { current: { eq: $slug } }) {
      title
      seoDescription
      seoKeywords
      navLanguage
      pageSections: _rawPageSections(resolveReferences: { maxDepth: 10 })
    }
  }
`;
